import React from 'react';

import Container from '$ui/container';

import Login from '$components/login';

const LoginPage = () => (
  <Container display='flex' className='bg-primary'>
    <Login />
  </Container>
);

export default LoginPage;
