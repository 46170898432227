import React, { useCallback } from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Link } from "gatsby";

import Heading from "$ui/heading";
import Input from "$ui/input";
import Button from "$ui/button";

import { useLogin } from "$hooks/auth";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please provide your email address"),
  password: Yup.string()
    .trim()
    .min(8, "Password is too short")
    .required("Please provide your password"),
});

const Login = () => {
  const { mutateAsync } = useLogin();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await mutateAsync(values);
        toast.success("Logged in successfully!");
        navigate("/");
      } catch (err) {
        toast.error(err.response?.data.message || "Network Error");
      }
    },
    [mutateAsync]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={loginSchema}
      initialValues={{
        email: "",
        password: "",
      }}
    >
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          className="w-[25rem] py-10 px-10 bg-gray-100 space-y-5 rounded-xl shadow-2xl phone:w-4/5"
        >
          <Heading variant="tertiary">Login now</Heading>

          <Input
            name="email"
            placeholder="Email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
          />

          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
          />

          <Button
            variant="filled"
            type="submit"
            className="w-full mt-8"
            isLoading={formik.isSubmitting}
          >
            Login
          </Button>

          <p className="text-sm text-center text-gray-500">
            Don't have an account?{" "}
            <Link to="/auth/signup" className="font-semibold">
              Register now
            </Link>
          </p>
          <p className="text-sm text-center text-gray-500">
            <Link to="/auth/forgot-password" className="font-semibold">
              Forgot your password?{" "}
            </Link>
          </p>
        </form>
      )}
    </Formik>
  );
};

export default Login;
